import React from "react";
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { useTranslation } from "react-i18next";
import { FaBolt } from 'react-icons/fa';
import { main, seeMore, badges } from './certifications-and-accreditations.module.css';
import { resetLink, resetList, internalContainer } from '../../styles/utils.module.css';
import Layout from '../../components/Layout';
import InternalSection from '../../components/InternalSection';
import InternalSectionTitle from '../../components/InternalSectionTitle';
import InternalSidebar from '../../components/InternalSidebar';
import useImages from '../../hooks/useImages';
import { findImageData } from '../../utils/tools';

export default () => {
    const { t } = useTranslation();

    const images = useImages();

    const copy = t('aboutUs.certificationsAndAccreditations', { returnObjects: true });

    return (
        <Layout>
            <InternalSection content={copy}>
                <div className={`${main} ${internalContainer}`}>
                    <div>
                        <InternalSectionTitle>{copy.mainContent.title}</InternalSectionTitle>
                        {copy.mainContent.segments[0].paragraphs.map((p, index) => (
                            <p key={index}>{p}</p>
                        ))}
                        <ul>
                            {copy.mainContent.segments[0].bulletPoints.map((li, index) => (
                                <li key={index}>{li}</li>
                            ))}
                        </ul>
                        <div className={badges}>
                            <Img fluid={findImageData(images, 'badge-2.png').fluid} />
                            <Img fluid={findImageData(images, 'badge-1.png').fluid} />
                        </div>
                        {copy.mainContent.segments[1].paragraphs.map((p, index) => (
                            <p key={index}>{p}</p>
                        ))}
                        <ul>
                            {copy.mainContent.segments[1].bulletPoints.map((li, index) => (
                                <li key={index}>{li}</li>
                            ))}
                        </ul>
                        <div className={`${resetList} ${seeMore}`}>
                            <h3>{copy.seeMore.title}</h3>
                            <p>{copy.seeMore.subtitle}</p>
                            <ul className={resetLink}>
                                {copy.seeMore.links.map((link, index) => (
                                    <li key={index}>
                                        <FaBolt size="1em" color="var(--lightYellow)"/>
                                        <Link to={link.url}>{link.label}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div>
                        <InternalSidebar />
                    </div>
                </div>
            </InternalSection>
        </Layout>
    );
}
